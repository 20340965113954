<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-10 mb-2">
      <v-card elevation="10" class="full-height bg-ligth">
        <div class="card-header">
          <h3  class="mb-0">PDV:  <span style="font-weight: 700;">{{ pdvsEvento.nome_pdv }}</span> 
            <br> EVENTO:  <span style="font-weight: 700;">{{ pdvsEvento.nome_evento }}</span>
            <br> ATUALIZADO:  <span v-if="ingressos[0]" style="font-weight: 700;">{{ ingressos[0].updated_at | formatDate }}</span></h3>
            
        </div>
        <!-- <div class="card-body table-responsive"> -->
          <!-- <div class="form-group row">
            <div class="col-md-6">
              <label class="col-md-12">Nome Evento:*</label>
              <input
                disabled
                type="text"
                class="form-control"
                v-model="pdvsEvento.nome_evento"
                placeholder="Digite a nome evento..."
              />
            </div>
            <div class="col-md-6">
              <label class="col-md-12">nome pdv:*</label>
              <input
                disabled
                type="text"
                class="form-control"
                v-model="pdvsEvento.nome_pdv"
                placeholder="Digite a endereco..."
              />
            </div>
            <div class="col-md-12 mt-1">
              <label class="col-md-12">Descrição:*</label>
              <input
                disabled
                type="text"
                class="form-control"
                v-model="pdvsEvento.descricao"
                placeholder="Digite a descricao..."
              />
            </div>
          </div> -->
        <!-- </div> -->
      </v-card>
    </div>
    <div class="col-md-12">
      <v-card elevation="10" class="full-height bg-ligth">
        <div class="card-header d-flex justify-content-between">
          <div>
            <h3 class="mb-0">INGRESSOS</h3>
          </div>
          <div>
            <v-btn
              elevation="4"
              outlined
              @click="adicionar()"
              small
              color="info"
              light
            >
              <v-icon color="red" dense large>mdi-plus p</v-icon>
           
            </v-btn>
            <v-btn
                      @click="confirm()"
                      small
                      class="ma-2"
                      color="primary"
                      dark
                    >
                      SALVE
                    </v-btn>
          </div>
        </div>
        <div class="card-body table-responsive">
          
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <!-- <th class="text-left">ID</th> -->
                  <th class="text-left">TIPO</th>
                  <th class="text-left">LOTE</th>
                  <th class="text-left">VALOR DO INGRESSO</th>
                  <th class="text-left">QUANTIDADE</th>
                  <th class="text-left">IMPRESSOS</th>
                  <th class="text-left">TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in ingressos" :key="index">
                  <!-- <td>
                    {{ item.id }}
                  </td> -->
                  <td>
                    <v-text-field v-model="item.tipo"></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      type="number"
                      v-model="item.lote"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-mask="variableWithMask[index]"
                      @input="valorFormat(index)"
                      v-model="item.valor"
                    ></v-text-field>
                  </td>

               
                  <td>
                    <v-text-field
                      type="number"
                      v-model="item.quantidade"
                    ></v-text-field>
                  </td>

                  <td>
                    <v-text-field
                      type="number"
                      v-model="item.impressos"
                    ></v-text-field>
                  </td>

                  <td v-if="item.id != null" style="font-size: 15px;">
                    {{
                      Number.isInteger(parseFloat(item.quantidade *item.valor.replace("R$", "").replace(",", "."))) ?  
                      'R$'+ String( parseFloat(item.quantidade *item.valor.replace("R$", "").replace(",", ".")) ) +',00'
                      :
              'R$'+ String( parseFloat(item.quantidade *item.valor.replace("R$", "").replace(",", ".")) ).replace('.',',')
                    }}
                  </td>
                  <td v-else style="font-size: 15px;">
                    R$0,00
                  </td>

                </tr>
                <tr>
                  <!-- <td>
                    {{ item.id }}
                  </td> -->
                  <td></td>
                  <td></td>
                  <td></td>
             

                  <td style="font-size: 15px">
                    Total:{{ somatotoal.quantidade }}
                  </td>

                  <td style="font-size: 15px">
                    Total: {{ somatotoal.impressos }}
                  </td>
                  <td v-if="somatotoal.valor>0" style="font-size: 15px">
                    {{  
                    Number.isInteger(somatotoal.valor ) ?  
                      'R$'+ String( somatotoal.valor) +',00'
                      :
              'R$'+ String( somatotoal.valor ).replace('.',',')
            }}
                  </td>
                </tr>
<br>
                <tr>
                  <th class="text-left">PIX</th>
                  <th class="text-left">DINHIERO</th>
                  <th class="text-left">DEBITO</th>
                  <th class="text-left">CREDITO</th>
                </tr>

                <tr >
                  <td>
                    <v-text-field
                      v-mask="variableWithMaskP[0]"
                      @input="valorFormatP(0)"
                      v-model="pix"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-mask="variableWithMaskD[0]"
                      @input="valorFormatD(0)"
                      v-model="dinheiro"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-mask="variableWithMaskCD[0]"
                      @input="valorFormatCD(0)"
                      v-model="debito"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-mask="variableWithMaskCC[0]"
                      @input="valorFormatCC(0)"
                      v-model="credito"
                    ></v-text-field>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import moneyFormat from "@/core/mixins/moneyFormat";
import dateFormat from "@/core/mixins/dateFormat";

export default {
  mixins: [fireAlert,moneyFormat,dateFormat],
  data() {
    return {
      form: {
        endereco: null,
        nome_evento: null,
        descricao: null,
      },
      // form2: {
      //   valor: null,
      //   tipo: null,
      //   quantidade: null,
      //   pdv_evento_id: null,
      //   dinheiro: null,
      //   debito: null,
      //   credito: null,

      //   pix: null,
      //   impressos: null,
      // },
      verif: false,
      ingressos: [],
      valor: 0,
      variableWithMask: [],
      variableWithMaskD: [],
      variableWithMaskCD: [],
      variableWithMaskCC: [],

      variableWithMaskP: [],
      debito:null,
      credito:null,
      pix :null,
      dinheiro:null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Pdvs Evento" }]);
  },
  created() {
    this.preenxerCampos();
  },
  computed: {
    somatotoal() {
      let soma = { impressos: null, quantidade: null, valor: null,total_valor:null };
      this.ingressos.forEach((element) => {
        if(element.id != null){
        soma.impressos += element.impressos;
        soma.quantidade += element.quantidade;
        soma.valor += (parseFloat(element.valor.replace("R$", "").replace(",", "."))  * element.quantidade);
        }
        // soma.total_valor =
        // soma.valor*
        // soma.quantidade;
      });
  
      return soma;
    },
    lista_eventos() {
      return this.$store.state.evento.lista_eventos.map((cat) => ({
        id: cat.id,
        label: cat.nome_evento,
      }));
    },
    lista_pdvs() {
      return this.$store.state.pdv.lista_pdvs.map((cat) => ({
        id: cat.id,
        label: cat.endereco,
      }));
    },

    pdvsEvento() {
      return this.$store.state.pdvsEvento.pdvs_evento;
    },
    lista_pdvs_evento_ingressos() {
      return this.$store.state.pdvsEventoIngresso.lista_pdvs_evento_ingressos;
    },
    mensagem_alert() {
      return this.$store.state.pdvsEventoIngresso.mensagem_alert;
    },
  },

  methods: {
    valorFormat(index) {
      let pa = "";
      for (let i = 0; i < this.ingressos[index].valor.length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }
      this.variableWithMask[index] = "R$" + pa + "#,##";
    },

    valorFormatD(index) {
      let pa = "";
      for (let i = 0; i < this.dinheiro.length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }
      this.variableWithMaskD[index] = "R$" + pa + "#,##";
    },
    valorFormatCD(index) {
      let pa = "";
      for (let i = 0; i < this.debito.length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }
      this.variableWithMaskCD[index] = "R$" + pa + "#,##";
      console.log(this.variableWithMask);
    },
    valorFormatCC(index) {
      let pa = "";
      for (let i = 0; i < this.credito.length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }
      this.variableWithMaskCC[index] = "R$" + pa + "#,##";
      console.log(this.variableWithMask);
    },
    valorFormatP(index) {
      let pa = "";
      for (let i = 0; i < this.pix.length; i++) {
        if (i > 5) {
          pa = pa + "#";
        }
      }
      this.variableWithMaskP[index] = "R$" + pa + "#,##";
    },
    adicionar() {
      this.ingressos.push({
        id: null,
        tipo: null,
        lote: null,
        quantidade: null,
        valor: null,
   
      });
    },
   async save() {
      this.ingressos.forEach(item => {
        if (item.id == null) {
        this.create(item)
      } else {
        this.update(item)

      }
      });
 

   
    },
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação atualiza esta lista no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.save()
          }
        },
      });
    },
    async create(item) {
      var form  ={};
      form.pdv_evento_id = this.$route.params.pdvs_evento_id
      form.tipo = item.tipo;
      form.quantidade = item.quantidade;
      form.impressos = item.impressos;

      form.valor = parseFloat(
        item.valor.replace("R$", "").replace(",", ".")
      );
   
      form.lote = item.lote;
      this.verif = true;
      await this.$store.dispatch(
        "pdvsEventoIngresso/create_pdvs_evento_ingresso",
        form
      );
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
      });
    },
    async update(item) {

      let form  ={};
      form.pdv_evento_id = this.$route.params.pdvs_evento_id
      form.tipo = item.tipo;
      form.quantidade = item.quantidade;
      form.impressos = item.impressos;
      form.valor = parseFloat(
        item.valor.replace("R$", "").replace(",", ".")
      );
if( this.ingressos[0].id  == item.id){
     
      form.dinheiro = parseFloat(
        this.dinheiro.replace("R$", "").replace(",", ".")
      );
      form.debito = parseFloat(
        this.debito.replace("R$", "").replace(",", ".")
      );
      form.credito = parseFloat(
        this.credito.replace("R$", "").replace(",", ".")
      );
      form.pix = parseFloat(this.pix.replace("R$", "").replace(",", "."));
}

      form.lote = item.lote;
      form.id = item.id;
      this.verif = true;
      await this.$store.dispatch(
        "pdvsEventoIngresso/update_pdvs_evento_ingresso",
        form
      );
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
      });
    },
    async preenxerCampos() {
      await this.$store.dispatch(
        "pdvsEvento/listar_pdvs_evento",
        this.$route.params.pdvs_evento_id
      );
      await this.$store.dispatch(
        "pdvsEventoIngresso/listar_pdvs_evento_ingresso",
        this.$route.params.pdvs_evento_id
      );

      this.ingressos =
        this.$store.state.pdvsEventoIngresso.pdvs_evento_ingresso;

      this.ingressos.forEach((element, index) => {
        element.valor =
          "R$" +
          (!element.valor
            ? "0,00"
            : element.valor.replace("R$", "").replace(".", ","));

      });

      
      this.debito =
          "R$" +
          (! this.ingressos[0].debito
            ? "0,00"
            :  this.ingressos[0].debito.replace("R$", "").replace(".", ","));
        this.credito =
          "R$" +
          (! this.ingressos[0].credito
            ? "0,00"
            : this.ingressos[0].credito.replace("R$", "").replace(".", ","));

        this.dinheiro =
          "R$" +
          (!this.ingressos[0].dinheiro
            ? "0,00"
            : this.ingressos[0].dinheiro.replace("R$", "").replace(".", ","));
        this.pix =
          "R$" +
          (!this.ingressos[0].pix
            ? "0,00"
            : this.ingressos[0].pix.replace("R$", "").replace(".", ","));

      if (this.pdvsEvento != "")
        this.form = {
          id: this.pdvsEvento.id,
          endereco: this.pdvsEvento.endereco,
          nome_pdv: this.pdvsEvento.nome_pdv,

          nome_evento: this.pdvsEvento.nome_evento,
          descricao: this.pdvsEvento.descricao,
        };
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>